export const PRIMARY = '#2E7BBF';
export const SECONDARY = '#2E3092';
export const ERROR = '#D72C0D';

export const GRAY_25 = '#FCFCFD';
export const GRAY_100 = '#F2F4F7';
export const GRAY_200 = '#EAECF0';
export const GRAY_300 = '#D0D5DD';
export const GRAY_400 = '#98A2B3';
export const GRAY_500 = '#667085';
export const GRAY_600 = '#475467';
export const GRAY_700 = '#344054';
export const GRAY_800 = '#1D2939';
export const YELLOW_500 = '#F59E0B';
export const Neutral_200 = '#E5E7EB';