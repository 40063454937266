import Link from 'next/link';
import ButtonBase from '@material-ui/core/ButtonBase';
import RoundSearchIcon from '@src_theme/icons/RoundSearch';
// import B2BIcon from '@src_theme/icons/B2B';
import BuildingIcon from '@src_theme/icons/Building';
// import Wishlist from '@modules/customer/plugins/Wishlist';
// import { MAX_WIDTH } from '@core/theme/vars';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withStyles } from '@material-ui/core/styles';
import { GRAY_200, PRIMARY } from '@src_theme/colors';
import ContentBoundary from '@commons/ContentBoundary';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Image from '@common_image';
import { getHost } from '@helpers/config';
import { getThumborUrl } from '@root/src/helpers/thumborConfig';
import { HOST } from '@config';
import TopMenu from './mtop';

const NotificationBell = dynamic(() => import('@modules/notification/plugins/NotificationBell'), { ssr: false });
const ShoppingBagIcon = dynamic(() => import('@modules/cart/plugins/ShoppingBag'), { ssr: false });
const ShoppingAt = dynamic(() => import('@common_shoppingat'), { ssr: false });
const AlertCoupon = dynamic(() => import('@modules/home/pages/default/components/AlertCoupon'), { ssr: true });
const PersonButton = dynamic(() => import('./PersonButton'), { ssr: false });
const OptionAutocomplete = dynamic(() => import('./Autocomplete/view'), { ssr: true });
const Autocomplete = dynamic(() => import('./Autocomplete'), { ssr: true });
const Menu = dynamic(() => import('@modules/theme/components/header/desktop/components/mcategory'), { ssr: true });
const AlertPromo = dynamic(() => import('@modules/home/pages/default/components/AlertPromo'), { ssr: true });
const StoreCreditAlert = dynamic(() => import('@modules/storecredit/pages/default/components/StoreCreditAlert'), { ssr: true });

const appEnv = typeof window !== 'undefined' ? window.APP_ENV : process.env.APP_ENV;
// saat running localhost:3000 gambar tidak rusak
const hostUrl = appEnv === 'local' ? HOST.dev : getHost();
const imgUrl = (url) => `${getThumborUrl()}/unsafe/0x0/filters:format(webp):quality(70)/${url}`;

const useStyles = makeStyles({
    root: {
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 10,
        backgroundColor: 'white',
    },
    actionMenuRoot: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
        '& > *': {
            flexGrow: 0,
            flexShrink: 0,
        },
        '@media (max-width: 1024px)': {
            gap: '8px',
        },
    },
    logo: {
        width: 96,
    },
    shoppingAt: {
        flexBasis: 300,
        '@media (max-width: 1024px)': {
            flexBasis: 180,
        },
    },
    searchBar: {
        flexGrow: 1,
        position: 'relative',
        '& > button': {
            position: 'absolute',
            top: 'calc(50% - 16px)',
            right: 4,
        },
        '& .MuiTextField-root': {
            margin: 0,
        },
    },
});

const RoundButton = withStyles({
    root: {
        borderRadius: 9999,
        backgroundColor: GRAY_200,
        color: PRIMARY,
        width: 40,
        height: 40,
    },
})(ButtonBase);

const ViewTopNavigation = (props) => {
    const {
        searchByClick,
        // value,
        storeConfig,
        handleSearch,
        setValue,
        data,
        loading,
        t,
        isLogin,
        coupon,
        customer,
        customerLoading,
        handleLogout,
        countCompare,
        show,
        setShow,
        b2bData,
    } = props;

    const styles = useStyles();

    return (
        <div id="header" className={classNames(styles.root, 'hidden-mobile')}>
            {show && <AlertCoupon coupon={coupon} t={t} show={show} setShow={setShow} isLogin={isLogin} />}
            <AlertPromo />
            {isLogin ? <StoreCreditAlert t={t} /> : ''}
            <TopMenu t={t} isLogin={isLogin} data={customer} handleLogout={handleLogout} countCompare={countCompare} />
            <ContentBoundary style={{ padding: '16px 0' }}>
                <div className={styles.actionMenuRoot}>
                    <Link href="/" prefetch={false}>
                        <a>
                            <Image
                                className={styles.logo}
                                src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                                alt="Mitra10"
                                width={96}
                                height={35}
                            />
                        </a>
                    </Link>
                    <div className={styles.shoppingAt}>
                        <ShoppingAt />
                    </div>
                    <div className={styles.searchBar}>
                        <Autocomplete setValue={setValue} handleSearch={handleSearch} OptionsItem={OptionAutocomplete} />
                        <ButtonBase onClick={searchByClick}>
                            <RoundSearchIcon width={32} />
                        </ButtonBase>
                    </div>
                    <RoundButton>
                        <ShoppingBagIcon withLink storeConfig={storeConfig} />
                    </RoundButton>
                    {Boolean(isLogin) && (
                        <RoundButton>
                            <NotificationBell withLink />
                        </RoundButton>
                    )}
                    {isLogin !== 0 && !b2bData?.status && (
                        <Link href={isLogin ? '/customer/account/createcompany' : '/customer/account/register?tab=company'} prefetch={false}>
                            <a>
                                <RoundButton>
                                    <BuildingIcon width={24} />
                                </RoundButton>
                            </a>
                        </Link>
                    )}
                    <PersonButton loading={customerLoading} t={t} isLogin={isLogin} data={customer} handleLogout={handleLogout} />
                    <Link href="/membership" prefetch={false}>
                        <img alt="member" src={imgUrl(`${hostUrl}/assets/img/member.png`)} height="40" width="68" loading="lazy" />
                    </Link>
                </div>
            </ContentBoundary>
            {!loading && <Menu data={data} storeConfig={storeConfig} />}
        </div>
    );
};

export default ViewTopNavigation;
